export class AppConsts {
    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static ndihUrl: string;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'DigitalCooperation',
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token',
    };

    static readonly Roles = {
        Admin: 'Admin',
        Company: 'Company',
        BackOffice: 'BackOffice',
    };
}
