import { ElementRef, Injector, Renderer2 } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AbpMultiTenancyService, FeatureCheckerService, LocalizationService, MessageService, NotifyService, PermissionCheckerService, SettingService } from 'abp-ng2-module';

import { AppSessionService } from '@shared/session/app-session.service';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { PermissionConsts } from '@shared/permission-consts';

export abstract class AppComponentBase {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    elementRef: ElementRef;
    titlePage: Title;
    renderer2: Renderer2;
    location: Location;

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.elementRef = injector.get(ElementRef);
        this.titlePage = injector.get(Title);
        this.renderer2 = injector.get(Renderer2);
        this.location = injector.get(Location);
    }

    get permissionsConst() {
        return PermissionConsts;
    }

    l(key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, this.localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    getUserSession() {
        return this.appSession.user;
    }

    setTitle(t: string) {
        this.titlePage.setTitle(this.l(t));
    }

    startLoading() {}

    stopLoading() {}

    goBack() {
        this.location.back();
    }

    isInRole(role: string) {
        return this.appSession.user.roleNames.some((r) => r === role);
    }
}
