import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';

@Component({
    selector: 'page-not-found',
    templateUrl: './PageNotFound.component.html',
    styleUrls: ['PageNotFound.component.scss'],
})
export class PageNotFoundComponent extends AppComponentBase {
    constructor(injector: Injector) {
        super(injector);
    }
}
