import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';
import { LoaderInterceptor } from '@shared/interceptors/loader.interceptor';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.ProposalReviewServiceProxy,
        ApiServiceProxies.ProposalServiceProxy,
        ApiServiceProxies.ProvinceServiceProxy,
        ApiServiceProxies.FileServiceProxy,
        ApiServiceProxies.ProposalStateServiceProxy,
        ApiServiceProxies.TransformingCompanyServiceProxy,
        ApiServiceProxies.CallServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
