export enum PermissionConsts {
    // Pages
    Pages_Tenants = 'Pages.Tenants',
    Pages_Roles = 'Pages.Roles',
    Pages_Users = 'Pages.Users',
    Pages_Users_Activation = 'Pages.Users.Activation',
    Pages_MyProposal = 'Pages.MyProposal',
    Pages_ProposalReview = 'Pages.ProposalReview',
    Pages_Proposals = 'Pages.Proposals',

    //User
    User_RequestEmailConfirmation = 'User.RequestEmailConfirmation',
    User_Manage = 'User.Manage',
    User_Detail = 'User.Detail',
    User_Create = 'User.Create',
    User_Edit = 'User.Edit',
    User_Delete = 'User.Delete',
    User_ChangePassword = 'User.ChangePassword',
    User_ForceResetPassword = 'User.ForceResetPassword',
    User_Deactivate = 'User.Deactivate',
    User_UnlockLogin = 'User.UnlockLogin',

    // Proposal
    Proposal_GetMyProposal = 'Proposal.GetMyProposal',
    Proposal_Create = 'Proposal.Create',
    Proposal_GetAll = 'Proposal.GetAll',
    Proposal_SelfAssign = 'Proposal.SelfAssign',

    // ProposalReview
    ProposalReview_Review = 'ProposalReview.Review',
    ProposalReview_Reject = 'ProposalReview.Reject',
}
